export default{
    title:"Home",
    store:"Store",
    cart:"Cart",
    services:"Services",
    filters:"Filters",
    menu:"Menu",
    contact_us:"Contact Us",
    about_us:"About us",
    shopping_card_info:"Gift Card",
    links:"Links",
    contact:"Contact",
    copyright:"Copyright 2025. Ingenius S.U.R.L.",
    price:"Price",
    brands:"Brands",
    count_product:"{product} products"
}